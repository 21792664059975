import { Options, Vue } from "vue-class-component";

import { ServicesRoutesEnum } from "../router";
import { serviceBackofficeService } from "@services/services.service";

import {
  ActiveCell,
  DynamicTable,
  YesNoCell,
  MyAutocomplete
} from "@components";

import { MainRoutesEnum } from "@/router/MainRoutesEnum";
import { Service } from "@/model/api/Service";
import { FilterMatchMode } from "primevue/api";

import i18n from "@/i18n";



@Options({
  components: {
    DynamicTable,
    MyAutocomplete
  }
})
export default class ServiceListPage extends Vue {
  readonly stateTableKey = 'ServiceListPage';
  filters: any = this.filtersSchema;

  get datatable(): any {
    return this.$refs.datatable;
  }

  beforeRouteLeave() {
    if (serviceBackofficeService?.cancelPendingRequests) {
      serviceBackofficeService.cancelPendingRequests();
    }
  }

  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith(ServicesRoutesEnum.NETWORK_PREFIX)) {
      sessionStorage.removeItem('ServiceListPage');
    }
    next();
  }

  goDashboard() {
    this.$router.push({ name: MainRoutesEnum.DASHBOARD });
  }

  goNetwork() {
    this.$router.push({ name: ServicesRoutesEnum.NETWORK_LIST });
  }

  goCategeories() {
    this.$router.push({ name: ServicesRoutesEnum.CATEGORIES_LIST });
  }

  get service() {
    return serviceBackofficeService;
  }

  goAdd() {
    this.$router.push({ name: ServicesRoutesEnum.SERVICES_EDIT, params: { serviceId: 0 } });
  }

  goToDetail(model: Service) {
    this.$router.push({ name: ServicesRoutesEnum.SERVICES_EDIT, params: { serviceId: model.id } });
  }

  goServiceVisibility(model: Service) {
    this.$router.push({ name: ServicesRoutesEnum.SERVICES_VISIBILITY, params: { serviceId: model.id } });
  }

  applyFilters() {
    this.datatable.loadData();
  }

  goReplicate(data: any) {
    
    /*
    if (this.$attrs.onPasswordReset) {
        this.$emit('passwordReset', data);
        return;
    }
    */
    
    const message_conf = i18n.global.t('replicate.confirm');
    const message_copied = i18n.global.t('replicate.done');
    const message_error = i18n.global.t('replicate.error');

    this.$confirmMessage(message_conf)
        .then(async response => {
            if (response) {
                this.$waitFor(
                    async () => {
                        await serviceBackofficeService.duplicate(data.id);
                        this.datatable.loadData(this.datatable.getFilters());
                        this.$successMessage(message_copied);
                    },
                    message_error
                )
            }
        })
  }


  get cols() {
    return [
      {
        field: 'name',
        header: this.$t('generic.input_name_label'),
      },
      {
        field: 'customer_descr',
        header: this.$t('admin.services.detail.customer_descr_label'),
      },
      {
        field: 'service_categories_text',
        header: this.$t('admin.service_categories.generic.categories_label'),
        filterField: 'service_category_id'
      },
      {
        field: 'is_service_provider',
        header: this.$t('admin.service_categories.generic.service_provider_label'),
        component: YesNoCell,
        style: 'min-width: 10px; max-width: 90px'
      },
      {
        field: 'is_active',
        header: this.$t('generic.input_state_label'),
        component: ActiveCell,
        style: 'min-width: 10px; max-width: 90px'
      },
      // {
      //   field: 'btn_visibility',
      //   header: this.$t('admin.service_categories.list.btn_visibility_label'),
      //   sortable: false,
      //   style: 'min-width: 10px; max-width: 70px',
      //   isAction: true
      // },
      {
        field: 'btn_replicate',
        header: this.$t('replicate.grid.col.title'),
        sortable: false,
        style: 'min-width: 10px; max-width: 70px',
        isAction: true
      }
    ];
  }

  get filtersSchema() {
    return {
      name: {
        matchMode: FilterMatchMode.STARTS_WITH
      },
      customer_descr: {
        matchMode: FilterMatchMode.STARTS_WITH
      },
      service_category_id: {
        matchMode: FilterMatchMode.EQUALS
      },
      is_service_provider: {
        matchMode: FilterMatchMode.EQUALS
      },
      is_active: {
        matchMode: FilterMatchMode.EQUALS
      }
    };
  }

  get filterYesNoOptions() {
    return [
      { name: this.$t('generic.all_sing_male_label'), value: null },
      { name: this.$t('yes_label'), value: true },
      { name: this.$t('no_label'), value: false },
    ];
  }

  get filterActiveOptions() {
    return [
      { name: this.$t('generic.all_sing_male_label'), value: null },
      { name: this.$t('active_label'), value: true },
      { name: this.$t('inactive_label'), value: false },
    ];
  }
}

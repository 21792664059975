import { VAT } from '@/model/api/Domain';
import { POSCategory } from '@/model/api/POS';
import { Service, ServiceCategory } from '@/model/api/Service';
import { FormPage } from '@components';
import { posCategoryService } from '@services/posCategory.service';
import { serviceCategoryService } from '@services/serviceCategory.service';
import { serviceBackofficeService } from '@services/services.service';
import {
  Form as VForm,
  Field as VField,
  ErrorMessage as VError
} from 'vee-validate';
import { Options, Vue } from "vue-class-component";
import { Prop } from 'vue-property-decorator';
import _ from 'lodash';

@Options({
  components: {
    VForm,
    VField,
    VError
  }
})
export default class ServiceEditPage extends FormPage {
  @Prop() readonly serviceId!: number;

  model: Service = new Service();
  filteredCategories: ServiceCategory[] = [];
  filteredPOSCategories: POSCategory[] = [];

  isLoading = false;

  get isNew() {
    return this.serviceId == 0;
  }

  get isValidServicePosCategories() {
    return this.model?.service_categories?.length && this.model?.pos_categories?.length;
  }

  beforeRouteLeave(to, from, next) {
    this.checkBeforeRouteLeave(next);
  }

  async created() {
    if (!this.isNew) {
      this.isLoading = true;
      try {
        this.model = await serviceBackofficeService.getById(this.serviceId)
      } catch (e) {
        this.$router.back();
      }
      this.isLoading = false;
    }
    this.modelFormChanged();
  }

  mounted() {
    this.$fixCommonTabIndex();
  }

  async doSave() {
    await this.$waitFor(
      async () => {
        if (this.isNew) {
          await serviceBackofficeService.create(this.model);
        } else {
          await serviceBackofficeService.updatePatch(this.model);
        }
        this.modelFormChanged();

        this.$successMessage(this.$t('data_saved_success'));
        this.$router.back();
      },
      this.$t('generic_error')
    )
  }

  async searchCategory({ query }) {
    const resp = await serviceCategoryService.autocomplete(query);
    this.filteredCategories = resp.data;
  }

  async searchPOSCategory({ query }) {
    const resp = await posCategoryService.autocomplete(query);
    this.filteredPOSCategories = resp.data;
  }

  get posCategories() {
    return this.model.pos_categories;
  }
  set posCategories(values) {
    this.model.pos_categories = _.uniqBy(values, 'id');
  }

}
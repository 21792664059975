import { BaseApiModel, IBaseApiAttachmentModel } from "@/model/common/BaseApiModel";
import { Country, Province, Region, VAT } from "./Domain";
import moment from 'moment-timezone'
import { Transform } from "class-transformer";
import { POSCategory } from "./POS";

export class Service extends BaseApiModel {
    name               : string  = null;
    is_active          : boolean = false;
    image_path_url     : string  = null;
    country_id         : number  = null;
    is_service_provider: boolean = false;
    note               : string  = null;
    wp_image_url       : string  = null;
    wp_page_url        : string  = null;
    commission_min_amt : number  = 0;
    commission_percent : number  = 0;
    pos_commission_min_amt : number  = 0;
    pos_commission_percent : number  = 0;
    customer_descr     : string  = null;

    service_categories: ServiceCategory[] = [];
    country: Country = null;    
    pos_categories: POSCategory[] = [];

    @Transform(
        ({ value, obj }) => moment(value, true).isValid() ? moment(value, true).toDate() : null,
        { toClassOnly: true }
    )
    @Transform(
        ({ value, obj }) => moment(value, true).isValid() ? moment(value, true).format('YYYY-MM-DD') : null,
        { toPlainOnly: true }
    )
    start_valid_at?: Date;

    @Transform(
        ({ value, obj }) => moment(value, true).isValid() ? moment(value, true).toDate() : null,
        { toClassOnly: true }
    )
    @Transform(
        ({ value, obj }) => moment(value, true).isValid() ? moment(value, true).format('YYYY-MM-DD') : null,
        { toPlainOnly: true }
    )
    end_valid_at?: Date;

    visibility: ServiceVisibility[] = [];

    readonly attachments: IBaseApiAttachmentModel[] = [];
}

export class ServiceCategory extends BaseApiModel {
    name: string;
    note?: string = null;
    is_active?: boolean;
}

export class ServiceVisibility extends BaseApiModel {
    province_id: number = null;
    region_id: number = null;

    province: Province = null;
    region: Region = null;
}
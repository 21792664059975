import { Sponsor } from '@/model/api/Sponsor';
import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { CRUD } from './base/crud';

class SponsorService extends CRUD<Sponsor, any> {
    readonly endPoint = `sponsors`;

    public autocomplete(q: string): Promise<PaginatedResponse<Sponsor>> {
        return this.get(`${this.endPoint}/autocomplete`, { params: { q: q } });
    }
}

export const sponsorService = new SponsorService();
import { Country, Province, Region } from "@/model/api/Domain";
import { Service, ServiceVisibility } from "@/model/api/Service";
import { domainService } from "@services/domain.service";
import { serviceBackofficeService } from "@services/services.service";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import {
  Form as VForm,
  Field as VField
} from 'vee-validate';
import { classToPlain, plainToClass } from "class-transformer";
import 'moment-timezone';
import moment from 'moment';
import { FormPage } from "@components";

@Options({
  components: {
    VForm,
    VField,
  }
})
export default class ServiceVisibilityPage extends FormPage {
  @Prop() readonly serviceId!: number;

  model: Service = new Service();

  get NO_COUNTRY(): Country {
    return { id: null, name: this.$t('all_plur_fem_label') };
  }
  get NO_REGION(): Region {
    return { id: null, name: this.$t('all_plur_fem_label') };
  }
  get NO_PROVINCE(): Province {
    return { id: null, name: this.$t('all_plur_fem_label') };
  }

  filteredCountries: Country[] = [];
  filteredRegions: Region[] = [];
  filteredProvinces: Province[] = [];

  isLoading = false;

  get selectedRegions(): Region[] {
    return (this.model as any).__regions || [];
  }
  set selectedRegions(values) {
    (this.model as any).__regions = values;
  }

  get selectedProvinces(): Province[] {
    return (this.model as any).__provinces || [];
  }
  set selectedProvinces(values) {
    (this.model as any).__provinces = values;
  }

  beforeRouteLeave(to, from, next) {
    this.checkBeforeRouteLeave(next);
  }

  async created() {
    this.initCountries();
    this.initRegions();
    this.initProvinces();

    this.isLoading = true;
    try {
      const resp = await serviceBackofficeService.getVisibility(this.serviceId);

      this.model = plainToClass(Service, resp);
      this.model.country = this.model.country || this.NO_COUNTRY;
      this.selectedRegions = this.model.visibility.map(v => v.region).filter(r => r) || [this.NO_REGION];
      this.selectedProvinces = this.model.visibility.map(v => v.province).filter(p => p) || [this.NO_PROVINCE];
    } catch (e) {
      console.log(e)
      this.$router.back();
    }
    this.isLoading = false;

    this.modelFormChanged();
  }

  async doSave() {
    const regions = this.selectedRegions.map(r => {
      const sv = new ServiceVisibility();
      sv.region_id = r.id;
      return sv;
    });

    const provinces = this.selectedProvinces.map(r => {
      const sv = new ServiceVisibility();
      sv.province_id = r.id;
      return sv;
    });

    this.model.visibility = [...regions, ...provinces];

    await this.$waitFor(
      async () => {
        await serviceBackofficeService.saveVisibility(classToPlain(this.model) as any);
        this.modelFormChanged();

        this.$successMessage(this.$t('data_saved_success'));
        this.$router.back();
      },
      this.$t('generic_error')
    )
  }

  // --- country ---
  async searchCountry({ query }) {
    const resp = await domainService.getCountriesAutocomplete(query);
    this.filteredCountries = resp.data;
    this.initCountries();
  }

  onSelectCountry({ value }) {
    this.model.country_id = value.id;
  }

  initCountries() {
    this.filteredCountries.unshift(this.NO_COUNTRY);
  }

  // --- region ---
  async searchRegion({ query }) {
    const resp = await domainService.getRegionsAutocomplete(query, this.model.country_id);
    this.filteredRegions = resp.data;
    this.initRegions();
  }

  onSelectRegion({ value }) {
  }

  initRegions() {
    this.filteredRegions.unshift(this.NO_REGION);
  }

  // --- province ---
  async searchProvince({ query }) {
    const resp = await domainService.getProvincesAutocomplete(query);
    this.filteredProvinces = resp.data;
    this.initProvinces();
  }

  onSelectProvince({ value }) {
  }

  initProvinces() {
    this.filteredProvinces.unshift(this.NO_PROVINCE);
  }
}
import { Network } from '@/model/api/Network';
import { FormPage } from '@components';
import { networkService } from '@services/network.service';
import {
  Form as VForm,
  Field as VField,
  ErrorMessage as VError
} from 'vee-validate';
import { Options, Vue } from "vue-class-component";
import { Prop } from 'vue-property-decorator';

@Options({
  components: {
    VForm,
    VField,
    VError
  }
})
export default class NetworkEditPage extends FormPage {
  @Prop() readonly networkId!: number;

  model: Network = {
    name: null,
    is_active: true
  };

  isLoading = false;

  get isNew() {
    return this.networkId == 0;
  }

  beforeRouteLeave(to, from, next) {
    this.checkBeforeRouteLeave(next);
  }

  async created() {
    if (!this.isNew) {
      this.isLoading = true;
      try {
        this.model = await networkService.getById(this.networkId)        
      } catch (e) {
        this.$router.back();
      }
      this.isLoading = false;
    }

    this.modelFormChanged();
  }

  async doSave() {
    await this.$waitFor(
      async () => {
        if (this.isNew) {
          await networkService.create(this.model);
        } else {
          await networkService.update(this.model);
        }
        this.modelFormChanged();

        this.$successMessage(this.$t('data_saved_success'));
        this.$router.back();        
      },
      this.$t('generic_error')
    )
  }

}
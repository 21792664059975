import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "subtitle" }
const _hoisted_3 = { class: "actions" }
const _hoisted_4 = { class: "content card" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "p-field p-col-6" }
const _hoisted_7 = { class: "p-field p-col-6" }
const _hoisted_8 = { class: "p-field p-col-6" }
const _hoisted_9 = { class: "p-field-radio" }
const _hoisted_10 = { class: "p-field-radiobutton" }
const _hoisted_11 = { for: "is_active_on" }
const _hoisted_12 = { class: "p-field-radiobutton" }
const _hoisted_13 = { for: "is_active_off" }
const _hoisted_14 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InfoIcon = _resolveComponent("InfoIcon")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_VField = _resolveComponent("VField")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_VError = _resolveComponent("VError")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_VForm = _resolveComponent("VForm")!

  return (_openBlock(), _createBlock(_component_VForm, {
    as: "div",
    id: "sponsor_edit_page",
    class: "page standard vertical-form"
  }, {
    default: _withCtx(({ handleSubmit, isSubmitting, meta: { valid } }) => [
      _createVNode("section", _hoisted_1, [
        _createVNode("div", null, [
          _createVNode("h1", null, _toDisplayString(_ctx.$t('admin.sponsors.detail.title')), 1),
          _createVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('admin.sponsors.detail.subtitle')), 1)
        ]),
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_Button, {
            label: _ctx.$t('btn_save_label'),
            class: "p-button-success",
            icon: "pi pi-save",
            iconPos: "right",
            disabled: !valid || isSubmitting,
            type: "submit",
            form: "edit_form"
          }, null, 8, ["label", "disabled"])
        ])
      ]),
      _createVNode("section", _hoisted_4, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock("h3", _hoisted_5, _toDisplayString(_ctx.$t('loading_label')), 1))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode("form", {
          class: "form_content",
          onSubmit: ($event: any) => (handleSubmit($event, _ctx.doSave)),
          id: "edit_form"
        }, [
          _createVNode("div", _hoisted_6, [
            _createVNode("label", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('generic.input_name_label')) + " ", 1),
              _createVNode(_component_InfoIcon, { text: "generic.input_name_label.tooltip" })
            ]),
            _createVNode(_component_VField, {
              name: "name",
              modelValue: _ctx.model.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.model.name = $event)),
              rules: "required"
            }, {
              default: _withCtx(({ field, meta: { dirty, valid } }) => [
                _createVNode(_component_InputText, _mergeProps({ autofocus: "" }, field, {
                  disabled: isSubmitting,
                  class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                  placeholder: _ctx.$t('generic.input_name_label')
                }), null, 16, ["disabled", "class", "placeholder"])
              ]),
              _: 2
            }, 1032, ["modelValue"])
          ]),
          _createVNode("div", _hoisted_7, [
            _createVNode("label", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('generic.input_note_label')) + " ", 1),
              _createVNode(_component_InfoIcon, { text: "generic.input_note_label.tooltip" })
            ]),
            _createVNode(_component_VField, {
              name: "note",
              label: "Note",
              modelValue: _ctx.model.note,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.model.note = $event)),
              rules: "required|max:1000"
            }, {
              default: _withCtx(({ field, meta: { valid } }) => [
                _createVNode(_component_Textarea, _mergeProps(field, {
                  disabled: isSubmitting,
                  class: [{ 'p-invalid': !valid }, "p-mt-1"],
                  placeholder: _ctx.$t('generic.input_note_label'),
                  rows: "3",
                  cols: "40"
                }), null, 16, ["disabled", "class", "placeholder"])
              ]),
              _: 2
            }, 1032, ["modelValue"]),
            _createVNode(_component_VError, {
              class: "p-error",
              name: "note"
            })
          ]),
          _createVNode("div", _hoisted_8, [
            _createVNode("label", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('generic.input_state_label')) + " ", 1),
              _createVNode(_component_InfoIcon, { text: "generic.input_state_label.tooltip" })
            ]),
            _createVNode("div", _hoisted_9, [
              _createVNode("div", _hoisted_10, [
                _createVNode(_component_RadioButton, {
                  id: "is_active_on",
                  modelValue: _ctx.model.is_active,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.model.is_active = $event)),
                  value: true
                }, null, 8, ["modelValue"]),
                _createVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('active_label')), 1)
              ]),
              _createVNode("div", _hoisted_12, [
                _createVNode(_component_RadioButton, {
                  id: "is_active_off",
                  modelValue: _ctx.model.is_active,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.model.is_active = $event)),
                  value: false
                }, null, 8, ["modelValue"]),
                _createVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('inactive_label')), 1)
              ])
            ])
          ])
        ], 40, ["onSubmit"]), [
          [_vShow, !_ctx.isLoading]
        ])
      ]),
      _createVNode("section", _hoisted_14, [
        _createVNode(_component_Button, {
          label: _ctx.$t('btn_go_back_label'),
          class: "p-button-secondary",
          icon: "pi pi-arrow-left",
          iconPos: "left",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.back()))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('btn_save_label'),
          class: "p-button-success p-ml-auto",
          icon: "pi pi-save",
          iconPos: "right",
          disabled: !valid || isSubmitting,
          type: "submit",
          form: "edit_form"
        }, null, 8, ["label", "disabled"])
      ])
    ]),
    _: 1
  }))
}
import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "subtitle" }
const _hoisted_3 = { class: "actions" }
const _hoisted_4 = { class: "content card" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "p-grid" }
const _hoisted_7 = { class: "p-field p-col-6" }
const _hoisted_8 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_9 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_10 = { class: "p-field p-col-3" }
const _hoisted_11 = { class: "p-field p-col-3" }
const _hoisted_12 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InfoIcon = _resolveComponent("InfoIcon")!
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_VField = _resolveComponent("VField")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_VForm = _resolveComponent("VForm")!

  return (_openBlock(), _createBlock(_component_VForm, {
    as: "div",
    id: "service_visibility_page",
    class: "page standard vertical-form"
  }, {
    default: _withCtx(({ handleSubmit, isSubmitting, meta: { valid } }) => [
      _createVNode("section", _hoisted_1, [
        _createVNode("div", null, [
          _createVNode("h1", null, _toDisplayString(_ctx.$t('admin.services.visibility.title')), 1),
          _createVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('admin.services.visibility.subtitle')), 1)
        ]),
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_Button, {
            label: _ctx.$t('btn_save_label'),
            class: "p-button-success",
            icon: "pi pi-save",
            iconPos: "right",
            disabled: !valid || isSubmitting,
            type: "submit",
            form: "edit_form"
          }, null, 8, ["label", "disabled"])
        ])
      ]),
      _createVNode("section", _hoisted_4, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock("h3", _hoisted_5, _toDisplayString(_ctx.$t('loading_label')), 1))
          : (_openBlock(), _createBlock("form", {
              key: 1,
              class: "form_content",
              onSubmit: ($event: any) => (handleSubmit($event, _ctx.doSave)),
              id: "edit_form"
            }, [
              _createVNode("div", _hoisted_6, [
                _createVNode("div", _hoisted_7, [
                  _createVNode("label", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('generic.country_label')) + " ", 1),
                    _createVNode(_component_InfoIcon, { text: "generic.country_label.tooltip" })
                  ]),
                  _createVNode(_component_VField, {
                    name: "country_id",
                    modelValue: _ctx.model.country_id,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.model.country_id = $event))
                  }, {
                    default: _withCtx(({ meta: { dirty, valid } }) => [
                      _createVNode(_component_AutoComplete, {
                        forceSelection: "",
                        dropdown: "",
                        modelValue: _ctx.model.country,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.model.country = $event)),
                        field: "name",
                        suggestions: _ctx.filteredCountries,
                        onComplete: _cache[2] || (_cache[2] = ($event: any) => (_ctx.searchCountry($event))),
                        onItemSelect: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onSelectCountry($event))),
                        class: { 'p-invalid': dirty && !valid }
                      }, null, 8, ["modelValue", "suggestions", "class"])
                    ]),
                    _: 2
                  }, 1032, ["modelValue"])
                ]),
                _createVNode("div", _hoisted_8, [
                  _createVNode("label", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('generic.regions_label')) + " ", 1),
                    _createVNode(_component_InfoIcon, { text: "generic.regions_label.tooltip" })
                  ]),
                  _createVNode(_component_AutoComplete, {
                    dropdown: "",
                    multiple: true,
                    modelValue: _ctx.selectedRegions,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.selectedRegions = $event)),
                    suggestions: _ctx.filteredRegions,
                    onComplete: _cache[6] || (_cache[6] = ($event: any) => (_ctx.searchRegion($event))),
                    field: "name"
                  }, null, 8, ["modelValue", "suggestions"])
                ]),
                _createVNode("div", _hoisted_9, [
                  _createVNode("label", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('generic.provinces_label')) + " ", 1),
                    _createVNode(_component_InfoIcon, { text: "generic.provinces_label.tooltip" })
                  ]),
                  _createVNode(_component_AutoComplete, {
                    dropdown: "",
                    multiple: true,
                    modelValue: _ctx.selectedProvinces,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.selectedProvinces = $event)),
                    suggestions: _ctx.filteredProvinces,
                    onComplete: _cache[8] || (_cache[8] = ($event: any) => (_ctx.searchProvince($event))),
                    field: "name"
                  }, null, 8, ["modelValue", "suggestions"])
                ]),
                _createVNode("div", _hoisted_10, [
                  _createVNode("label", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('admin.services.visibility.input_from_date_label')) + " ", 1),
                    _createVNode(_component_InfoIcon, { text: "admin.services.visibility.input_from_date_label.tooltip" })
                  ]),
                  _createVNode(_component_Calendar, {
                    modelValue: _ctx.model.start_valid_at,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.model.start_valid_at = $event)),
                    showButtonBar: true,
                    monthNavigator: true,
                    yearNavigator: true,
                    yearRange: "1950:2040"
                  }, null, 8, ["modelValue"])
                ]),
                _createVNode("div", _hoisted_11, [
                  _createVNode("label", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('admin.services.visibility.input_to_date_label')) + " ", 1),
                    _createVNode(_component_InfoIcon, { text: "admin.services.visibility.input_to_date_label.tooltip" })
                  ]),
                  _createVNode(_component_Calendar, {
                    modelValue: _ctx.model.end_valid_at,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.model.end_valid_at = $event)),
                    showButtonBar: true,
                    monthNavigator: true,
                    yearNavigator: true,
                    yearRange: "1950:2040"
                  }, null, 8, ["modelValue"])
                ])
              ])
            ], 40, ["onSubmit"]))
      ]),
      _createVNode("section", _hoisted_12, [
        _createVNode(_component_Button, {
          label: _ctx.$t('btn_go_back_label'),
          class: "p-button-secondary",
          icon: "pi pi-arrow-left",
          iconPos: "left",
          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$router.back()))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('btn_save_label'),
          class: "p-button-success p-ml-auto",
          icon: "pi pi-save",
          iconPos: "right",
          disabled: !valid || isSubmitting,
          type: "submit",
          form: "edit_form"
        }, null, 8, ["label", "disabled"])
      ])
    ]),
    _: 1
  }))
}
import { Service } from '@/model/api/Service';
import { User } from '@/model/api/User';
import { CRUD } from './base/crud';

interface UploadFiles {
    file: File
}

class ServiceBackofficeService extends CRUD<Service, any> {
    readonly endPoint = `services`;

    uploadFiles(userId: number, { file }: UploadFiles) {

        const formData = new FormData();

        if (file) {
            formData.append("file", file);
        }

        const options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            // onUploadProgress: onUploadProgress
        };

        return this.post<any>(`${this.endPoint}/${userId}/upload-attachment`, formData, options);
    }

    getVisibility(serviceId: number) {
        return this.get<Service>(`${this.endPoint}/${serviceId}/visibility`);
    }

    saveVisibility(service: Service) {
        return this.patch<Service>(`${this.endPoint}/${service.id}/visibility`, service);
    }

    duplicate(serviceId: number) {
        return this.post<Service>(`${this.endPoint}/${serviceId}/duplicate`);
    }
}

export const serviceBackofficeService = new ServiceBackofficeService();




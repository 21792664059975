import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "network_list_page",
  class: "page standard"
}
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = { class: "content card" }
const _hoisted_5 = { class: "header" }
const _hoisted_6 = { class: "scrollable-datatable" }
const _hoisted_7 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_DynamicTable = _resolveComponent("DynamicTable")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("section", _hoisted_2, [
      _createVNode("div", null, [
        _createVNode("h1", null, _toDisplayString(_ctx.$t('admin.networks.list.title')), 1),
        _createVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('admin.networks.list.subtitle')), 1)
      ])
    ]),
    _createVNode("section", _hoisted_4, [
      _createVNode("div", _hoisted_5, [
        _createVNode(_component_Button, {
          label: _ctx.$t('btn_add_new_label'),
          class: "p-button-success",
          icon: "pi pi-plus",
          iconPos: "right",
          onClick: _ctx.goAdd
        }, null, 8, ["label", "onClick"])
      ]),
      _createVNode("div", _hoisted_6, [
        _createVNode(_component_DynamicTable, {
          ref: "datatable",
          class: "p-mt-3",
          cols: _ctx.cols,
          service: _ctx.service,
          filters: _ctx.filters,
          stateKey: _ctx.stateTableKey,
          onEdit: _cache[4] || (_cache[4] = ($event: any) => (_ctx.goToDetail($event)))
        }, {
          "name-filter": _withCtx(({ col }) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: _ctx.filters.name.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.filters.name.value = $event)),
              onKeydown: _withKeys(_ctx.applyFilters, ["enter"]),
              class: "p-column-filter",
              placeholder: col.header
            }, null, 8, ["modelValue", "onKeydown", "placeholder"])
          ]),
          "note-filter": _withCtx(({ col }) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: _ctx.filters.note.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.filters.note.value = $event)),
              onKeydown: _withKeys(_ctx.applyFilters, ["enter"]),
              class: "p-column-filter",
              placeholder: col.header
            }, null, 8, ["modelValue", "onKeydown", "placeholder"])
          ]),
          "is_active-filter": _withCtx(({ col }) => [
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.filters.is_active.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.filters.is_active.value = $event)),
              options: _ctx.filterActiveOptions,
              optionLabel: "name",
              optionValue: "value",
              placeholder: col.header
            }, null, 8, ["modelValue", "options", "placeholder"])
          ]),
          _: 1
        }, 8, ["cols", "service", "filters", "stateKey"])
      ])
    ]),
    _createVNode("section", _hoisted_7, [
      _createVNode(_component_Button, {
        label: _ctx.$t('btn_go_back_label'),
        class: "p-button-secondary",
        icon: "pi pi-arrow-left",
        iconPos: "left",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.back()))
      }, null, 8, ["label"])
    ])
  ]))
}
import { Options, Vue } from "vue-class-component";

import {
  ActiveCell,
  DynamicTable
} from "@components";
import { serviceCategoryService } from "@services/serviceCategory.service";
import { ServicesRoutesEnum } from "../router";
import { ServiceCategory } from "@/model/api/Service";
import { FilterMatchMode } from "primevue/api";

@Options({
  components: {
    DynamicTable
  }
})
export default class CategoryListPage extends Vue {
  readonly stateTableKey = 'CategoryListPage';
  filters: any = this.filtersSchema;

  get datatable(): any {
    return this.$refs.datatable;
  }

  beforeRouteLeave() {
    if (serviceCategoryService?.cancelPendingRequests) {
      serviceCategoryService.cancelPendingRequests();
    }
  }

  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith(ServicesRoutesEnum.NETWORK_PREFIX)) {
      sessionStorage.removeItem('CategoryListPage');
    }
    next();
  }

  get service() {
    return serviceCategoryService;
  }

  goAdd() {
    this.$router.push({ name: ServicesRoutesEnum.CATEGORIES_EDIT, params: { categoryId: 0 } });
  }

  goToDetail(model: ServiceCategory) {
    this.$router.push({ name: ServicesRoutesEnum.CATEGORIES_EDIT, params: { categoryId: model.id } });
  }

  applyFilters() {
    this.datatable.loadData();
  }

  get cols() {
    return [
      {
        field: 'name',
        header: this.$t('generic.input_name_label'),
      },
      {
        field: 'note',
        header: this.$t('generic.input_note_label'),
      },
      {
        field: 'is_active',
        header: this.$t('generic.input_state_label'),
        component: ActiveCell
      }]
  }

  get filtersSchema() {
    return {
      name: {
        matchMode: FilterMatchMode.STARTS_WITH
      },
      note: {
        matchMode: FilterMatchMode.CONTAINS
      },
      is_active: {
        matchMode: FilterMatchMode.EQUALS
      }
    };
  }

  get filterActiveOptions() {
    return [
      { name: this.$t('generic.all_sing_male_label'), value: null },
      { name: this.$t('active_label'), value: true },
      { name: this.$t('inactive_label'), value: false },
    ];
  }
}
